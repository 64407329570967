@use 'sass:map';
@use 'global/font';

@import 'layout';

.section-main__header,
.section-intro,
.section-info,
.section-advice,
.section-footer__navigation,
.section-footer__backlink,
.section-header__inside > * {
    display: none;
}

.section-header__inside > .section-header__logo {
    display: block;

    img {
        width: 200px;
    }
}

.section-footer {
    font-size: 12px;
    border: 2px solid #656565;

    .content-text {
        color: #999;
        font-size: 14px;

        p {
            margin-top: 0;
        }
    }

    &__columns {
        display: flex;
    }

    &__infodrog {
        padding: 10px;
        border-right: 2px solid #656565;

        .content-text {
            font-weight: map.get(font.$font-weight, medium);
            white-space: nowrap;
        }
    }

    &__partner {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        padding: 10px;

        .content-text {
            flex-basis: 100%;
        }

        .content-hyperlink {
            padding: 10px;

            img {
                width: auto;
                height: 40px;
            }
        }
    }
}

.handorgel__content {
    display: block !important;
    height: auto !important;
}

a {
    text-decoration: underline !important;
}
